import React from "react";
import './index.css';

const SpecialButton = () => {
    return (
        <div data-aos="fade-up" data-aos-delay="350" className="special-button-container">
            <a className="special-button" href="https://t.me/lil_admln"><span>Telegram Main</span></a>
            <div className="alt-btns">
                <div className="addons-container">
                    <a className="special-button" href="https://t.me/scamer"><span>Add'l Telegram #1</span></a>
                    <a className="special-button" href="https://t.me/xbet_admin"><span>Add'l Telegram #2</span></a>
                </div>
            </div>
        </div>
    )
}

export default SpecialButton;