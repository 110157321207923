import React, {useEffect, useState} from "react";
import './index.css';
import {AnimatedCounter} from "react-animated-counter";

const FooterStat = ({api = '/', interval = 100000, description = ''}) => {

    const [number, setNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchNumber = async () => {
            try {
                const response = await fetch(api);
                const data = await response.json();
                if (data.number !== undefined) {
                    setIsLoading(false);
                    setNumber(data.number);
                }
            } catch (error) {
                console.error("Error fetching the number:", error);
            }
        };

        fetchNumber();

        const intervalId = setInterval(fetchNumber, interval);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="footer-value">
            {isLoading ? <span>Loading...</span> : <AnimatedCounter value={number} incrementColor={'#1b66c8'} includeDecimals={false} fontSize={'16px'} />}
            <span className="footer-curr">{description}</span>
        </div>
    )
}

export default FooterStat;