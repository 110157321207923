import {Canvas, useLoader, useThree, useFrame } from '@react-three/fiber';
import React, {useEffect, useState} from 'react';
import * as THREE from 'three';
import './index.css';
import {OrbitControls} from "@react-three/drei";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";

function Scene() {
    const fbx = useLoader(FBXLoader, '/model180.fbx')
    const { camera, scene } = useThree();
    const lightRef = React.useRef();
    const groupRef = React.useRef();
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px) and (orientation: portrait)").matches);

    // Check for mobile once on component mount
    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 768px) and (orientation: portrait)").matches);
    }, []);

    useEffect(() => {
        if (fbx) {
            const box = new THREE.Box3().setFromObject(fbx);
            const size = box.getSize(new THREE.Vector3());
            const center = box.getCenter(new THREE.Vector3());

            // Center the model
            fbx.position.set(-center.x, -center.y, -center.z);

            fbx.traverse((child) => {
                if (child.isMesh && child.material.emissive) {
                    child.material.emissive.lerp(new THREE.Color("rgb(35,36,44)"), 0.7);
                }
            });

            const maxDim = Math.max(size.x, size.y, size.z);
            const fov = camera.fov * (Math.PI / 180);
            let cameraZ = isMobile ? Math.abs(maxDim / 2 * Math.tan(fov * 0.85)) : Math.abs(maxDim / 2 * Math.tan(fov * 0.95));

            camera.position.z = cameraZ;
            const minZ = box.min.z;
            const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ;

            camera.far = cameraToFarEdge * 3;
            camera.updateProjectionMatrix();
        }
    }, [fbx, camera]);

    useFrame(() => {
        if (lightRef.current) {
            lightRef.current.position.set(camera.position.x, camera.position.y + 5, camera.position.z);
        }
    });


    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const [rotationY, setRotationY] = useState(0);
    const [rotationX, setRotationX] = useState(0);

    useEffect(() => {
        if (!isMobile) {
            const handleMouseMove = (e) => {
                const { clientX, clientY } = e;
                const { innerWidth, innerHeight } = window;

                // Normalize the mouse position from -0.5 to 0.5
                const normX = (clientX / innerWidth) * 2 - 1;
                const normY = (clientY / innerHeight) * 2 - 1;

                setMouseX(normX);
                setMouseY(normY);
            };
            window.addEventListener('mousemove', handleMouseMove);
            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, []);

    useFrame(() => {
        if (groupRef.current) {
            const rotationFactor = 0.5;
            const targetRotationY = Math.PI + mouseX * rotationFactor;
            const targetRotationX = mouseY * rotationFactor;

            const newRotationY = THREE.MathUtils.lerp(rotationY, targetRotationY, 0.05);
            const newRotationX = THREE.MathUtils.lerp(rotationX, targetRotationX, 0.05);

            groupRef.current.rotation.y = newRotationY;
            groupRef.current.rotation.x = newRotationX;

            setRotationY(newRotationY);
            setRotationX(newRotationX);
        }
    });

    function easeInOutSine(t) {
        return -(Math.cos(Math.PI * t) - 1) / 2;
    }

    const maxDistance = 10;
    const movementSpeed = 0.003;
    const [direction, setDirection] = useState(1);
    const [movedDistance, setMovedDistance] = useState(0);
    const [progress, setProgress] = useState(0);
    useFrame(() => {
        if (groupRef.current) {
            const newProgress = progress + direction * movementSpeed;

            if (newProgress >= 1 || newProgress <= 0) {
                setDirection(-direction);
                setProgress(newProgress);
            } else {
                setProgress(newProgress);
            }
            const easedProgress = easeInOutSine(progress);
            const movement = maxDistance * easedProgress;
            groupRef.current.position.y = movement;
        }
    });



    return (
        <>
            <group ref={groupRef}>
                <primitive object={fbx} />
            </group>
            <hemisphereLight skyColor="#ffffff" groundColor="#000000" intensity={7} position={[0, 1, 0]} />
    </>
    )


}

function ModelMain() {
    return (
        <Canvas className="model-container" data-aos="fade-left" data-aos-delay="400">
            <OrbitControls
                enableZoom={false}
                enableRotate={false}
                enablePan={false}
                minPolarAngle={90 * (Math.PI / 180)}
                maxPolarAngle={90 * (Math.PI / 180)}
            />
            <Scene />
        </Canvas>
    );
}

export default ModelMain;
