import React from "react";
import './index.css';

const Button = ({text, url, index}) => {
    return (
        <a data-aos="fade-up" data-aos-delay={(index* 50) + 350} className="link" href={url} target="_blank" rel="noreferrer">
            <span>{text}</span>
        </a>
    )
}

export default Button;