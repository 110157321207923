import React from "react";
import './index.css';
import Button from "../Button";
import SpecialButton from "../SpecialButton";

const MainDescription = () => {
    return (
        <article className="article-description">
            <h1 data-aos="fade-down" data-aos-delay="50">
                Добро пожаловать, я - <span className="text-gradient">@lil Admin</span>
            </h1>
            <span className="headline-desc" data-aos="fade-right" data-aos-delay="150">Со-основатель легендарной команды Xbet Team</span>
            <p data-aos="fade-right" data-aos-delay="250">Этот сайт служит платформой для демонстрации наших последних, и наиболее важных проектов. Для вашей уверенности и защиты я так-же включил каталог официальных тегов, исключая возможность обмана от моего имени. Помните: доверять - хорошо, контролировать - еще лучше.</p>
            <div className="links">
                <div className="link-pc">
                    <SpecialButton/>
                </div>
                <div className="links-mobile">
                    <Button
                        text="Telegram Main"
                        url="https://t.me/lil_admln"
                        index={1}
                    />
                    <Button
                        text="Add'l Telegram #1"
                        url="https://t.me/scamer"
                        index={1}
                    />
                    <Button
                        text="Add'l Telegram #2"
                        url="https://t.me/xbet_admin"
                        index={1}
                    />
                </div>
                <Button
                    text="Xbet Team"
                    url="https://t.me/xbetteam_bot"
                    index={1}
                />
                <Button
                    text="Xbet Projects"
                    url="https://t.me/XbetProjects"
                    index={2}
                />
                <Button
                    text="TG Co-founder #2"
                    url="https://t.me/tllmenxbet"
                    index={3}
                />
            </div>
        </article>
    )
}

export default MainDescription;