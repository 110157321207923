import React, {useEffect, useState} from "react";
import MainDescription from "./components/MainDescription";
import ModelMain from "./components/ModelMain";
import Background from "./components/Background";
import GrainEffect from "./components/GrainEffect";
import Footer from "./components/Footer";
import AOS from 'aos';

function App() {
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px) and (orientation: portrait)").matches);

    useEffect(() => {
        // Initialize the media query list.
        const mql = window.matchMedia("(orientation: portrait)");

        // Create an event listener for changes in the media query.
        const mediaQueryChangeListener = (event) => {
            setIsMobile(event.matches);
        };

        mql.addListener(mediaQueryChangeListener);

        // Cleanup the listener when component unmounts.
        return () => mql.removeListener(mediaQueryChangeListener);
    }, []);

    useEffect(() => {
        // Dynamically import the CSS when the user is not on mobile.
        if (!isMobile) {
            import('aos/dist/aos.css').then(() => {
                AOS.init({
                    startEvent: 'DOMContentLoaded',
                    offset: 0
                });
            });
        }
    }, [isMobile]);


    return (
        <>
            <GrainEffect />
            <Background />
            <MainDescription />
            <ModelMain />
            <Footer />
        </>
    )
}

export default App;
