import React from "react";
import './index.css';
import FooterStat from "../FooterStat";

const Footer = () => {
    return (
        <footer>
            <div className="footer-element" data-aos="fade-up" data-aos-delay="700">
                <span className="credits">© <span className="blue">Xbet Team</span> x <span className="blue">Lil Admin</span></span>
            </div>
            <div className="footer-element">
                <div className="footer-info-element" data-aos="fade-up" data-aos-delay="850">
                    <span>Количество профитов:</span>
                    <FooterStat
                        api={'https://oalechlshrb.ebatzerkalo.ru/ksjfhkdvbrvbtk'}
                        description={'Профитов'}
                    />
                </div>
                <div className="footer-info-element" data-aos="fade-up" data-aos-delay="950">
                    <span>Сумма профитов:</span>
                    <FooterStat
                        api={'https://oalechlshrb.ebatzerkalo.ru/dfvshvgjrbhdfg'}
                        description={'RUB'}
                    />
                </div>
            </div>
        </footer>
    )
}

export default Footer;